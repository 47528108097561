<template>
  <div class="neteaseClassifyDetailsPage">
    <HomePageTop/>
    <div class="container">
      <div class="navigation">
        <span style="font-size: 14px"><a href="/">トップページ</a> > &nbsp;&nbsp;{{ categoryInfo.name_jp }}</span>
      </div>
      <div class="class_all">
        <div v-for="(item,index) in categoryInfo.sub_list" :key="index"
             :style="index==categoryInfo.sub_list.length-1?'border-bottom:none':''"
             class="classify">
          <div>
            <div class="tow_class">{{ item.name_jp }}</div>
          </div>
          <div style="display: flex;flex-wrap: wrap;">
            <span v-for="(subItem,subIndex) in item.sub_list" :key="subIndex"
                  :class="subItem.class_id==$route.query.childrenId?'action':''"
                  class="childrenClassify"
                  @click="goAuchor(subItem.class_id)">{{ subItem.name_jp }}</span>
          </div>
        </div>
      </div>
      <div class="merchandise">
        <div v-for="(item,index) in categoryGoodsList" :id="item.classification.class_id" :key="index"
             class="piece">
          <div class="title">
            <div class="class_name">{{ item.classification.name_jp }}</div>
          </div>
          <div class="content">
            <div v-for="(goodItem,goodIndex) in item.goods" :key="goodIndex" class="goodsListContainer">
              <el-link :href="goodItem.link" :underline="false" target="_blank">
                <img :src="goodItem.primaryPicUrl" style="width:240px;height: 240px;">
                <div>
                  <p :title="goodItem.TranslationName" class="data-title u-line-2">
                    {{ goodItem.TranslationName }}
                  </p>
                  <p class="goodPrice">
                    <span>{{ goodItem.price }}元</span>
                    <span>（{{ (Number(goodItem.price) * Number($store.state.exchangeRate)).toFixed(0) }}円）</span>
                  </p>
                </div>
              </el-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot/>
  </div>
</template>

<script>
import HomePageTop from "@/components/head/HomePageTop.vue";
import Foot from "@/components/foot/Foot.vue";

export default {
  data() {
    return {
      categoryGoodsList: [],
      categoryInfo: {}
    };
  },
  components: {
    HomePageTop,
    Foot,
  },
  created() {
    this.$api.neteaseCategoryList({class_id: this.$route.query.id}).then((result) => {
      this.categoryInfo = result.data;
    })
    this.$api.neteaseCategoryGoodsList({class_id: this.$route.query.id}).then((result) => {
      result.data.forEach((item) => {
        item.goods.forEach((goodsItem) => {
          goodsItem.link = `/ProductDetails?goods_id=${goodsItem.SPUID}&fromPlatform=${goodsItem.goods_type}`
        })
      })
      this.categoryGoodsList = result.data;
    })
  },
  methods: {
    goAuchor(id) {
      document.querySelector(`[id='${id}']`).scrollIntoView(true)
      let auchor = this.$el.querySelector(`[id='${id}']`)
      window.scrollTo({
        top: auchor.offsetTop - 55, //举例：用户点击 第二个标签后 页面就会滚动到第二个标签的高度
        behavior: "smooth" //丝滑滚动
      });
    },
  },
};

</script>

<style lang="scss" scoped>
.neteaseClassifyDetailsPage {
  margin: 0 auto;
}

.container {
  width: 1250px;
  font-size: 14px;
  margin: 20px auto;
  /*font-family: monospace;*/
}

.font-color-red {
  color: #ff0004;
}

.title {
  margin: 0 auto;
  /*text-align: center;*/
  font-style: italic;
}

.cardTitle {
  text-align: center;
  font-size: 22px;
  margin: 30px auto;
}

.cards {
  display: flex;
  justify-content: space-between;
}

.card {
  background-repeat: no-repeat;
  background-size: 100% 100%;

  width: 300px;
  height: 220px;
  text-align: center;
}

.merchandise {
  width: 1400px;
  margin: 0 auto;

  .piece {
    margin-top: 40px;
    cursor: pointer;
  }

  .content {
    display: flex;
    width: 1400px;
    margin-top: 20px;
    flex-wrap: wrap;

    .goodsListContainer {
      width: 264px;
      margin-right: 20px;
      background: white;
      padding: 10px 15px;
      margin-bottom: 20px;
      box-sizing: border-box;
    }

    .goodsListContainer:nth-child(5n) {
      margin-right: 0;
    }

    .goodPrice {
      color: #B4272D;
      font-size: 12px;
    }
  }
}

.card p:nth-of-type(1) {
  color: white;
  text-align: center;
  font-size: 25px;
  margin: 15px;
}

.card p:nth-of-type(2) {
  text-align: center;
  font-size: 25px;
  margin-top: 50px;
}

.card span {
  font-weight: bold;
  font-size: 32px;
}

.chargeFree {
  margin: 10px;
}

.rule-title {
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  margin: 30px auto;
}

.class_all {
  width: 1400px;
  margin: 20px auto;
  border: 1px solid #ccc;
  padding: 30px;
  background: white;

  .classify {
    display: flex;
    padding: 20px 0;
  }

  .childrenClassify {
    margin-right: 20px;
    cursor: pointer
  }
}

.tow_class {
  /*text-align-last: justify;*/
  text-align: justify;
  width: 210px;
  font-weight: 900;
  font-size: 15px;
  margin-right: 40px;
}

.classify {
  border-bottom: 2px dashed #E1E1E1;
}

.title {
  height: 50px;
  border-bottom: 2px solid #e0b37f;

}

.class_name {
  line-height: 48px;
  font-style: initial;
  font-size: 18px;
  font-weight: bold;
  color: #d69449;
  background: #f5f0e9;
  text-align: left;
  padding-left: 35px;
  display: inline-block;
  padding-right: 35px;
}

.card-class:hover {
  color: #CC9756 !important;
}

.card-class {
  color: black !important;
}

.action {
  color: #CC9756 !important;
}

.data-title {
  font-size: 12px;
  color: #000000;
  margin-bottom: 14px;
}
</style>